import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class NotificationsService {
  private notificationOnChanges: Subject<any> = new Subject<any>();
  private notificationOnDestroy: Subject<any> = new Subject<any>();


  constructor(
  ) {
  }

  get notificationOnChanges$() {
    return this.notificationOnChanges.asObservable();
  }

  get notificationOnDestroy$() {
    return this.notificationOnDestroy.asObservable();
  }

  sendNotificationOnChanges(data) {
    this.notificationOnChanges.next(data);
  }

  sendNotificationOnDestroy() {
    this.notificationOnDestroy.next(0);
  }


}

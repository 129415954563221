import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LangService {
  private langOnChanges: Subject<any> = new BehaviorSubject<any>('es');

  constructor(
  ) {
  }

  get langOnChanges$() {
    return this.langOnChanges.asObservable();
  }

  sendLangOnChanges(data) {
    this.langOnChanges.next(data);
  }


}

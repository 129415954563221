import { Component, OnInit } from '@angular/core';
import { AuthService } from './../auth/auth.service';
import { LangSelectorDialogComponent } from '../lang-selector/lang-selector.component';
import { ThemeSelectorDialogComponent } from '../theme-selector/theme-selector.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})


export class ToolbarComponent implements OnInit {
  version: string;

  constructor(
    public auth: AuthService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  // toggleDarkTheme(checked: boolean) {
  //   this.themeService.setDarkTheme(checked);
  // }

  openLangSelectorDialog() {
    const langDialog = new MatDialogConfig();
    langDialog.autoFocus = true;
    langDialog.data = {};
    const langDialogRef = this.dialog.open(LangSelectorDialogComponent, langDialog);
  }

  openThemeSelectorDialog() {
    const langDialog = new MatDialogConfig();
    langDialog.autoFocus = true;
    const langDialogRef = this.dialog.open(ThemeSelectorDialogComponent, langDialog);
  }

}

import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
const CSV_TYPE = 'text/csv;charset=utf-8"';
const CSV_EXTENSION = '.csv';
const EXCEL_TYPE = 'application/vnd.openxmlformat-officedocument.spreadsheetml.sheet';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class CSVService {
  constructor() { }
  saveAsCSVFile(data: any, fileName: string): void {
    const blob: Blob = new Blob([data], { type: CSV_TYPE });
    FileSaver.saveAs(blob, fileName + CSV_EXTENSION);
  }

  saveAsXLSXFile(data: any, fileName: string): void {
    const blob: Blob = new Blob([data], { type: EXCEL_TYPE });
    FileSaver.saveAs(blob, fileName + EXCEL_EXTENSION);
  }
}

<div class="langDialog">

  <h2 class="langDialog__title" mat-dialog-title>{{'HOME.LANG_SELECTOR' | translate }}</h2>

  <mat-dialog-content>
    <div [formGroup]="form" class="langSelector">
      <mat-radio-group formControlName="langSelector" aria-label="Select an option" (change)="toogleLanguage()">
        <mat-radio-button value="es">{{ 'LANG_SELECTOR.SPANISH_LABEL' | translate }}</mat-radio-button>
        <mat-radio-button value="en">{{ 'LANG_SELECTOR.ENGLISH_LABEL' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="" (click)="close()">
      {{'CLOSE' | translate }}
    </button>

  </mat-dialog-actions>

</div>

import { Component, OnInit, Input } from '@angular/core';
import { NotificationsService } from './notifications.component.service';
import { Notification } from './notification/notification.component';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input()
  auth;

  notifications: Notification[] = [
    // {
    //   content: ['VOLUME', 'AGROSAFE SRL', 'Maiz rosario', '2323'],
    //   type: 'report',
    //   message: 'REPORT_MESSAGE',
    //   route: '/mmk/volumeReports/detail/d9ad9435-7a62-41d0-baa1-699f93876154'
    // }
  ];

  constructor(
    private notificationsService: NotificationsService
  ) {
    this.notificationsService.notificationOnChanges$.subscribe(notifications => {
      this.notifications.unshift(notifications);
    })
    this.notificationsService.notificationOnDestroy$.subscribe(notifications => {
      this.notifications = [];
    })
  }

  ngOnInit(): void {
    // this.notificationsService.notificationOnChanges$.subscribe(notifications => console.log(notifications))
  }

  menuClosed(): void {
    this.notifications = [];
  }

}

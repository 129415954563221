import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { retryWhen, share } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';


@Injectable()
export class WebSocketService {
    private ws: WebSocket;
    private wsObservable: Observable<any>;

    public wsStatus = new Subject();

    constructor() {

        this.wsObservable = Observable.create((observer: any) => {

            this.ws = new WebSocket(this.makeConfig());

            this.ws.onopen = (event) => {
              console.log('onopen')
                this.wsStatus.next(true);
            };

            this.ws.onclose = (event) => {
                if (event.wasClean) {
                    observer.complete();
                } else {
                    observer.error(event);
                }
            };

            this.ws.onerror = (event) => {
                this.wsStatus.next(false);
                observer.error(event);
            };

            this.ws.onmessage = (event) => {
                observer.next(event.data);
            };

            return () => {
                this.ws.close();
            };
        }).pipe(share());
    }

    close(): void {
        this.ws.close();
    }

    getDataStream(): Observable<any> {
        return Observable.create((observer: any) => {
            const subscription = this.wsObservable.subscribe(observer);
             console.log('getDataStream')
            return () => {
              console.log('unsubscribe')
                subscription.unsubscribe();
            };
        }).pipe(retryWhen((error: any) => error.delay(3000)));
    }

    sendData(message: any): void {
        this.ws.send(message);
    }

    makeConfig(): string {
          return environment.WS_URL;
      }

}

import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpBackend } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { MaterialModule } from './shared/modules/material.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LangService } from './shared/services/lang.service';
import { StorageService } from './shared/services/local-storage.service';
import { CSVService } from './shared/services/excel.service';

import { PreviousRouteService } from './shared/services/previous-route.service';
import { ThemeService } from './shared/services/theme.service';
import { ResizeService } from './shared/services/resize.service';
// import { InterceptorService } from './auth/interceptor.service';
import { AppRoutingModule } from './app.routes';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEsAr from '@angular/common/locales/es-AR';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS,
   MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

registerLocaleData(localeEn, 'en')
registerLocaleData(localeEsAr, 'es');

import { AuthService } from './auth/auth.service';
// Sub Applications
import { ToolbarComponent } from './toolbar/toolbar.component';
import { LangSelectorDialogComponent } from './lang-selector/lang-selector.component';
import { ThemeSelectorDialogComponent } from './theme-selector/theme-selector.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsService } from './notifications/notifications.component.service';
import { NotificationComponent } from './notifications/notification/notification.component';
import { WebSocketService } from './shared/services/channels/web-socket.service';
import { ChannelService } from './shared/services/channels/channel.service';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
export function createTranslateLoader(httpBackend: HttpBackend): TranslateHttpLoader {
    return new TranslateHttpLoader(new HttpClient(httpBackend), './assets/i18n/app/', '.json');
}


// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: ['l', 'LL'],
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        // CallbackComponent,
        ToolbarComponent,
        LangSelectorDialogComponent,
        ThemeSelectorDialogComponent,
        NotificationsComponent,
        NotificationComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        // RouterModule.forRoot(AppRoutingModule.Routes, {preloadingStrategy: PreloadAllModules}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpBackend]
            },
            isolate: true
        })
    ],
    providers: [
        AuthService,
        LangService,
        ThemeService,
        StorageService,
        CSVService,
        NotificationsService,
        PreviousRouteService,
        WebSocketService,
        ChannelService,
        { provide: 'windowObject', useValue: window },
        { provide: LOCALE_ID, useValue: 'es' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: InterceptorService,
        //   multi: true
        // },
        ResizeService,
        provideCharts(withDefaultRegisterables())
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

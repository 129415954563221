import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { fadeAnimation } from './animations';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './shared/services/theme.service';
import { PreviousRouteService } from './shared/services/previous-route.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Router, NavigationStart } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit {
  checkingSession = true;
  opened: boolean;
  isDarkTheme: Observable<boolean>;
  previousUrl: string = null;
    currentUrl: string = null;
  constructor(
    public translate: TranslateService,
    public auth: AuthService,
    private changeDetector: ChangeDetectorRef,
    private themeService: ThemeService,
    private overlay: OverlayContainer,
    private previousRouteService: PreviousRouteService,
    private router: Router
  ) {
    this.translate.addLangs(['es', 'en'])
    this.translate.setDefaultLang('es');
    this.translate.use('es');
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.previousRouteService.setPreviousUrl(this.previousUrl);
    });
  }

  ngOnInit() {
    this.isDarkTheme = this.themeService.isDarkTheme;
    this.isDarkTheme.subscribe(result => {
      if (result === true) {
        this.overlay.getContainerElement().classList.add('dark-theme');
      } else {
        this.overlay.getContainerElement().classList.remove('dark-theme');
      }
    });

    this.changeDetector.detectChanges();

  }

}

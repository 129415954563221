<div [ngClass]="{'dark-theme': isDarkTheme | async}">

  <div class="mat-app-background">

      <main role="main" class="example-container" [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
        <app-notifications [auth]="auth"></app-notifications>
        <button [matMenuTriggerFor]="belowMenu" class="main__cog" *ngIf="auth.loggedIn" ><svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39"><path d="M19.5 0C8.747 0 0 8.747 0 19.5S8.747 39 19.5 39 39 30.253 39 19.5 30.253 0 19.5 0ZM13 16.25c0-3.59 2.996-6.5 6.5-6.5 3.503 0 6.5 2.91 6.5 6.5v1.625c0 3.59-2.997 6.5-6.5 6.5-3.504 0-6.5-2.91-6.5-6.5V16.25Zm6.5 19.5c-3.954 0-7.58-1.423-10.402-3.78 1.364-2.572 4.037-4.345 7.152-4.345h6.5c3.115 0 5.788 1.773 7.152 4.345A16.177 16.177 0 0 1 19.5 35.75Z" fill="#fff"/></svg></button>
        <mat-menu #belowMenu="matMenu" yPosition="below">
          <app-toolbar *ngIf="auth.loggedIn"></app-toolbar>
        </mat-menu>
        <router-outlet #o="outlet"></router-outlet>
      </main>

  </div>
</div>

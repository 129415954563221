import { EventEmitter, Injectable } from '@angular/core';

import { WebSocketDemultiplexerCallback } from './web-socket-demultiplexer-callback';
import { WebSocketService } from './web-socket.service';
import { WebSocketStream } from './web-socket-stream';

@Injectable()
export class ChannelService {

  private eventByStream: Map<string, EventEmitter<Object>>;
  public webSocketService: WebSocketService;

  constructor() {
    this.webSocketService = new WebSocketService();
    this.webSocketService.getDataStream().subscribe(
      (message) => {
        this.onmessage(JSON.parse(message));
        // this.onmessage(message)
      },
      (message) => {
        console.log('channel')
      },
      () => {
        console.log('complete');
      }
    );

    this.eventByStream = new Map<string, EventEmitter<Object>>();
  }

  private onmessage(data: WebSocketStream): void {
    if (!this.eventByStream.has(data.stream)) {
      // No one is subscribed to this stream, ignore
      console.log('onmessage no stream', data, data.stream)

      return;
    }

    const eventEmitter = this.eventByStream.get(data.stream);
    eventEmitter.emit(data.payload);
  }

  sendData(action, payload): void {
    this.webSocketService.sendData(JSON.stringify({
      'action': action,
      'content': payload,
    }));
  }

  closeChannel(): void {
    this.webSocketService.close();
  }

  subscribe(stream: string, callback: WebSocketDemultiplexerCallback): void {
    if (!this.eventByStream.has(stream)) {
      this.eventByStream.set(stream, new EventEmitter());
    }
    console.log('channel subscribe')

    const eventEmitter = this.eventByStream.get(stream);
    eventEmitter.subscribe(callback);
  }

  unsubscribe(stream: string): void {
    if (this.eventByStream.has(stream)) {
      console.log('channel UNsubscribe')
      this.eventByStream.delete(stream);
    }
  }
}

import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject, fromEvent } from 'rxjs';
import { map, auditTime, startWith } from 'rxjs/operators';

export interface WindowSize {
  height: number,
  width: number
};

@Injectable()
export class ResizeService {
  constructor(@Inject('windowObject') private window: Window) {

    const lala = fromEvent(window, 'resize')
      .pipe(
        startWith(window),
        auditTime(100),
        map(() => <WindowSize>{
          width: window.innerWidth,
          height: window.innerHeight
        })
        // map(event => <WindowSize>{
        //   width: event['currentTarget'].innerWidth,
        //   height: event['currentTarget'].innerHeight
        // })
      ).subscribe((windowSize) => {
        this.windowSizeChanged.next(windowSize);
      })
  }

  readonly windowSizeChanged = new BehaviorSubject<WindowSize>(<WindowSize>{
    width: this.window.innerWidth,
    height: this.window.innerHeight
  });
}

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { LangService } from '../shared/services/lang.service';
import { DateAdapter } from '@angular/material/core';
import { BaseUnsubscriberComponent } from '../shared/components/base-unsubscriber/base-unsubscriber.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss']
})
export class LangSelectorDialogComponent extends BaseUnsubscriberComponent implements OnInit, OnDestroy {
  selectedLanguage = 'es';
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private langService: LangService,
    private dateAdapter: DateAdapter<Date>,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data  ) {
    super();
    this.translateService.setDefaultLang(this.selectedLanguage);
    this.translateService.use(this.selectedLanguage);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
        langSelector: [''],
    });
    this.form.controls['langSelector'].setValue('es');
  }

  toogleLanguage() {
    this.translateService.use(this.form.value.langSelector);
    this.langService.sendLangOnChanges(this.form.value.langSelector);
    moment.locale(this.form.value.langSelector);
    this.dateAdapter.setLocale(this.form.value.langSelector);

  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BaseUnsubscriberComponent } from '../shared/components/base-unsubscriber/base-unsubscriber.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from '../shared/services/theme.service';
@Component({
  selector: 'app-theme-selector',
  templateUrl: './theme-selector.component.html',
  styleUrls: ['./theme-selector.component.scss']
})
export class ThemeSelectorDialogComponent extends BaseUnsubscriberComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private themeService: ThemeService,
    private changeDetector: ChangeDetectorRef,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data  ) {
    super();
  }

  ngOnInit(): void {
    this.themeService.isDarkTheme.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      this.form = this.fb.group({
          themeSelector: [response.toString()],
      });
    })

    this.changeDetector.detectChanges();


  }

  toogleTheme() {
    this.themeService.setDarkTheme(this.form.value.themeSelector === 'false' ? false : true);
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

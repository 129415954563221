<div class="notification">
  <p class="notification__message">
    <time class="notification__time" *ngIf="notification.time">{{notification.time}} - </time> 
    <span *ngIf="notification.hasContentInMessage && notification.contentIsStart">{{notification.content}}</span>
    <span >{{ ('NOTIFICATIONS.' + notification.message) | translate}}</span>
    <span class="notification__content--middle" *ngIf="notification.hasContentInMessage && notification.contentIsMiddle">{{notification.content}}</span>
    <span  *ngIf="notification.hasContentInMessage && notification.contentIsMiddle">{{ ('NOTIFICATIONS.' + notification.message1) | translate}}</span>
    <span *ngIf="notification.hasContentInMessage && notification.contentIsEnd">{{notification.content}}</span>
  </p>

  <table class="notification__content--table" *ngIf="!notification.hasContentInMessage">
    <tr>
      <td *ngFor="let item of notification.content">
        {{item | translate}}
      </td>
    </tr>

  </table>

  <button class="primary-btn" [routerLink]="notification.route" [queryParams]="notification.queryParam ? notification.queryParam : null">{{'NOTIFICATIONS.MORE' | translate}}</button> 
</div>

import { HomeComponent } from './home/home.component';
import { AuthService } from './auth/auth.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InterceptorService } from './auth/interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataSynchroComponent } from './subapps/data-synchro/data-synchro.component';
const routes: Routes = [
    {
      path: 'mmk',
      canActivate: [AuthService],
      loadChildren: () => import('./subapps/mmk/mmk-module/mmk-module.module').then(m => m.MMKSharedModule)
    },
    {
      path: 'ta',
      canActivate: [AuthService],
      loadChildren: () => import(
        './subapps/trading-analytics/trading-analytics-module/trading-analytics-module.module').then(m => m.TASharedModule)
    },
    {
      path: 'integration',
      canActivate: [AuthService],
      loadChildren: () => import(
        './subapps/data-synchro/data-synchro.module').then(m => m.DataSynchroModule)
    },
    { path: '',  component: HomeComponent, pathMatch: 'full'},
    { path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ]
})
export class AppRoutingModule { }

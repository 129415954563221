<button [matMenuTriggerFor]="notificationsMenu" class="main__notifications" (menuClosed)="menuClosed()" *ngIf="auth.loggedIn">
  <mat-icon [matBadgeHidden]="notifications.length === 0"  [matBadge]="notifications.length" matBadgeColor="warn">notifications</mat-icon>
<!-- Include text description of the icon's meaning for screen-readers -->
<span class="cdk-visually-hidden">
Notification count
</span>
</button>
<mat-menu #notificationsMenu="matMenu" yPosition="below" class="notifications__menu">
  <p mat-menu-item *ngIf="notifications.length === 0">{{'NOTIFICATIONS.NO_MESSAGES' | translate}}</p>
  <app-notification *ngFor="let notification of notifications" [notification]="notification"></app-notification>
</mat-menu>

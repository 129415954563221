import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BaseUnsubscriberComponent } from '../../shared/components/base-unsubscriber/base-unsubscriber.component';
export interface Notification {
  type: string,
  content: any,
  message: string,
  route: string,
  time?: string,
  hasContentInMessage?: boolean,
  contentIsMiddle?: boolean,
  message1?: string,
  contentIsEnd?: boolean,
  contentIsStart?: boolean,
  queryParam?: any;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseUnsubscriberComponent implements OnInit, OnDestroy {
  @Input()
  notification: Notification;

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

<div class="themeDialog">

  <h2 class="themeDialog__title" mat-dialog-title>{{'HOME.THEME_SELECTOR' | translate }}</h2>

  <mat-dialog-content>
    <div [formGroup]="form" class="themeSelector">
      <mat-radio-group formControlName="themeSelector" aria-label="Select an option" (change)="toogleTheme()">
        <mat-radio-button value="false">{{ 'THEME_SELECTOR.LIGHT_THEME' | translate }}</mat-radio-button>
        <mat-radio-button value="true">{{ 'THEME_SELECTOR.DARK_THEME' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="" (click)="close()">
      {{'CLOSE' | translate }}
    </button>

  </mat-dialog-actions>

</div>

import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-unsubscriber',
  templateUrl: './base-unsubscriber.component.html',
  styleUrls: []
})

export abstract class BaseUnsubscriberComponent implements OnDestroy {

  ngUnsubscribe = new Subject<void>();

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

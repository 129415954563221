import {environment} from '../../environments/environment';

interface AuthConfig {
  clientID: string;
  domain: string;
  callbackURL: string;
  audience: string;
}

export const AUTH_CONFIG: AuthConfig = {
  clientID: environment.clientID,
  domain: 'primaryventures.auth0.com',
  callbackURL: environment.callbackURL,
  audience: environment.audience
};

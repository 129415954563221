import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AuthService } from './../auth/auth.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  // ctx: any;
   version: string;
  // stars: any[] = [];
  // mouse: any = {x: 0, y: 0};
  // numberStars = 100;
  // @ViewChild('myCanvas') myCanvas: ElementRef;
  // public context: CanvasRenderingContext2D;

  constructor(public auth: AuthService) {
    this.version = environment.version;
  }

  ngAfterViewInit(): void {
    // this.ctx = (<HTMLCanvasElement>this.myCanvas.nativeElement).getContext('2d');
    //
    // this.ctx.canvas.width = window.innerWidth;
    // this.ctx.canvas.height = window.innerHeight;
    //
    // // Push stars to array
    // for (let i = 0; i < this.numberStars; i++) {
    //   this.stars.push({
    //     x: Math.random() * this.ctx.canvas.width,
    //     y: Math.random() * this.ctx.canvas.height,
    //     radius: Math.random() * 1 + 1,
    //     vx: Math.floor(Math.random() * 50) - 25,
    //     vy: Math.floor(Math.random() * 50) - 25
    //   });
    // }
    //
    // this.tick();
  }

  ngOnInit() {
  }


  draw(ctx, stars, mouse) {
    // ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    //
    // ctx.globalCompositeOperation = 'lighter';
    //
    // for (let i = 0, x = stars.length; i < x; i++) {
    //   const s = stars[i];
    //
    //   ctx.fillStyle = '#fff';
    //   ctx.beginPath();
    //   ctx.arc(s.x, s.y, s.radius, 0, 2 * Math.PI);
    //   ctx.fill();
    //   ctx.fillStyle = 'black';
    //   ctx.stroke();
    // }
    //
    // ctx.beginPath();
    //
    // for (let i = 0, x = stars.length; i < x; i++) {
    //   const starI = stars[i];
    //   ctx.moveTo(starI.x, starI.y);
    //
    //   if (this.distance(mouse, starI) < 150) {ctx.lineTo(mouse.x, mouse.y)};
    //
    //   for (let j = 0, p = stars.length; j < p; j++) {
    //     const starII = stars[j];
    //     if (this.distance(starI, starII) < 150) {
    //       ctx.lineTo(starII.x, starII.y);
    //     }
    //   }
    // }
    // ctx.lineWidth = 0.05;
    // ctx.strokeStyle = 'white';
    // ctx.stroke();
  }

  distance( point1, point2 ) {
    // let xs = 0;
    // let ys = 0;
    //
    // xs = point2.x - point1.x;
    // xs = xs * xs;
    //
    // ys = point2.y - point1.y;
    // ys = ys * ys;
    //
    // return Math.sqrt( xs + ys );
  }

  // Update star locations

  update(stars) {
    // for (let i = 0, x = stars.length; i < x; i++) {
    //   const s = stars[i];
    //
    //   s.x += s.vx / 60;
    //   s.y += s.vy / 60;
    //   if (s.x < 0 || s.x > this.ctx.canvas.width) {s.vx = -s.vx};
    //   if (s.y < 0 || s.y > this.ctx.canvas.height) {s.vy = -s.vy};
    // }
  }

  // Update and draw
  //
  // tick = () => {
  //   this.draw(this.ctx, this.stars, this.mouse);
  //   this.update(this.stars);
  //   requestAnimationFrame(this.tick);
  // }



}

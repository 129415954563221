import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import {StorageService} from './local-storage.service';

export enum ThemeMode {
  DARK, LIGHT
}

@Injectable()
export class ThemeService {
  private readonly THEME_KEY = 'SurveillanceTheme';
  private readonly DARK_THEME_VALUE = 'DARK';
  private readonly LIGHT_THEME_VALUE = 'LIGHT';

  private _darkTheme = new BehaviorSubject<boolean>(this.storage.get(this.THEME_KEY) === this.DARK_THEME_VALUE);
  isDarkTheme = this._darkTheme.asObservable();


  constructor(private storage: StorageService) {}

  setDarkTheme(isDarkTheme: boolean): void {
    if (isDarkTheme) {
      this.storage.set(this.THEME_KEY, this.DARK_THEME_VALUE);
    } else {
      this.storage.set(this.THEME_KEY, this.LIGHT_THEME_VALUE);
    }
    this._darkTheme.next(isDarkTheme);
  }
}

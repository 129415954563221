import all from '../../package.json';

export const environment = {
  version: all.version,
  production: false,
  BASE_URI: 'https://api.surveillance-dev.pmydata.com/',
  API_URL: 'https://api.surveillance-dev.pmydata.com/api/v1',
  callbackURL: 'https://app.surveillance-dev.pmydata.com/callback/',
  WS_URL: 'wss://api.surveillance-dev.pmydata.com/live/',
  audience: 'https://surveillance-dev.pmydata.com/api',
  clientID: 'ZdFxYmKETdeENdDfbZSYtCdTB8p2L1yg'
};
